<template>
  <v-card outlined>
    <v-card-text>
      <v-data-table
        flat 
        disable-sort
        class="mb-3 title" 
        hide-default-footer
        loader-height="2"
        :headers="headers"
        :loading="fetching"
        :items="permissions.data"
      >
        <template v-slot:item.display_name="{ item }">
          {{ item.display_name }}
        </template>

        <template v-slot:item.micro_service="{ item }">
          {{ item.micro_service.name }}
        </template>

        <template v-slot:item.subject="{ item }">
          {{ item.subject || 'n/a' }}
        </template>

        <template v-slot:item.is_internal="{ item }">
          <v-chip 
            dark
            small
            color="green"
          >
            {{ item.is_internal }}
          </v-chip>
        </template>

        <template v-slot:item.is_essential="{ item }">
          <v-chip 
            dark
            small
            color="green"
          >
            {{ item.is_essential }}
          </v-chip>
        </template>

        <template v-slot:item.actions="{ item }">
          <v-btn
            dark
            label
            small
            color="primary"
            class="caption ttn"
            @click="$emit('edit', item)"
          >
            Edit  
          </v-btn> 
        </template>
      </v-data-table>

      <app-pagination
        v-if="permissions.meta"
        :meta="permissions.meta"
        @pageChanged="pageChanged"
      ></app-pagination>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
export default {
  data () {
    return {
      page: 1,
      fetching: true,
      headers: [
        { text: 'Name', value: 'display_name' },
        { text: 'Microservice', value: 'micro_service' },
        { text: 'Subject', value: 'subject' },
        { text: 'Internal', value: 'is_internal' },
        { text: 'Essential', value: 'is_essential' },
        { text: 'Actions', value: 'actions' },
      ],
    }
  },

  computed: {
    ...mapGetters({
      permissions: 'getPermissions'
    })
  },

  methods: {
    ...mapActions([
      'setPermissions'
    ]),

    loadPermissions (search = null) {
      return this.setPermissions({
        params: {
          page: this.page,
          ...(search && { search })
        }
      })
    },

    pageChanged (page) {
      this.page = page
      this.fetching = true
      this.loadPermissions().then(() => {
        this.fetching = false
      })
    },
  },

  mounted () {
    this.loadPermissions().then(() => {
      this.fetching = false
    })
  }
}
</script>